
import Vue from 'vue'
import { passwordAllowedSymbolsRegExp, passwordSpecialCharacters } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'PasswordRepairSetup',
  data: () => ({
    form: {
      valid: false,
      fields: {
        password1: {
          value: '',
          type: 'password',
          errorMessages: [],
          rules: [
            (v: string) => Boolean(v.length) || 'newPassword.errors.required',
            (v: string) => v.length >= 8 || 'newPassword.errors.fieldIsShort',
            (v: string) => passwordAllowedSymbolsRegExp.test(v) || 'newPassword.errors.onlyLatin',
            (v: string) => v.search(/[a-z]/g) >= 0 || 'newPassword.errors.lowerCharsRequired',
            (v: string) => v.search(/[A-Z]/g) >= 0 || 'newPassword.errors.upperCharsRequired',
            (v: string) => v.search(/[0-9]/g) >= 0 || 'newPassword.errors.numbersRequired',
            (v: string) => passwordSpecialCharacters.test(v) || 'newPassword.errors.specialSymbol'
          ]
        },
        password2: {
          value: '',
          type: 'password',
          errorMessages: [],
          rules: [
            (v: string) => Boolean(v.length) || 'newPassword.errors.confirmRequired',
            (v: string) => v.length >= 8 || 'newPassword.errors.fieldIsShort',
            (v: string) => passwordAllowedSymbolsRegExp.test(v) || 'newPassword.errors.onlyLatin',
            (v: string) => v.search(/[a-z]/g) >= 0 || 'newPassword.errors.lowerCharsRequired',
            (v: string) => v.search(/[A-Z]/g) >= 0 || 'newPassword.errors.upperCharsRequired',
            (v: string) => v.search(/[0-9]/g) >= 0 || 'newPassword.errors.numbersRequired',
            (v: string) => passwordSpecialCharacters.test(v) || 'newPassword.errors.specialSymbol'
          ]
        }
      },
      success: false,
      responseErrors: [],
      successTimer: 0,
      isLoading: false,
      passwordPattern: passwordAllowedSymbolsRegExp.source
    }
  }),
  watch: {
    'form.successTimer' (val: number, oldVal: number) {
      if (val === 0 && oldVal === 1) {
        this.$router.replace({ name: 'SignIn' })
      }
    },
    'form.fields.password2.value' (v: string) {
      const vm = this as any
      const item: any = vm.form.fields.password1
      const item2: any = vm.form.fields.password2
      const index = item.errorMessages.findIndex((item: any) => item === 'newPassword.errors.notEqual')
      if (v !== item.value) {
        if (index < 0) {
          item.errorMessages.push('newPassword.errors.notEqual')
          item2.errorMessages.push('newPassword.errors.notEqual')
        }
      } else {
        item.errorMessages.splice(index, 1)
        item2.errorMessages.splice(index, 1)
      }
    }
  },
  methods: {
    changePasswordInputType (item: string): void {
      const vm = this as any
      vm.form.fields[item].type = vm.form.fields[item].type === 'password' ? 'text' : 'password'
    },
    checkPasswordsEqual (): void {
      const vm = this as any
      const p1 = vm.form.fields.password1.value
      const p2 = vm.form.fields.password2.value
      if (p1.length && p2.length) {
        if (p1 !== p2) {
          if (!vm.form.fields.password2.errorMessages.length) {
            vm.form.fields.password2.errorMessages.push('This value should be equal value of field above')
          }
        } else {
          vm.form.fields.password2.errorMessages.shift()
        }
      }
    },
    async validate () {
      const vm = this as any
      vm.form.isLoading = true
      const request: any = {}
      for (const [key, value] of Object.entries(vm.form.fields)) {
        request[key] = (value as any).value
      }
      request.key = vm.$route.params.token
      try {
        const response: any = await vm.$services.authorization.signup.verifyToken(request)
        if (response.success) {
          vm.form.success = true
          vm.form.successTimer = 5
          setInterval(() => {
            if (vm.form.successTimer > 0) vm.form.successTimer--
          }, 1000)
        } else {
          vm.form.success = false
          vm.form.responseErrors = []
          for (const [_, value] of Object.entries(response.data) as any) {
            if (typeof value === 'string') {
              vm.form.responseErrors.push(value)
            } else {
              for (const err of value) {
                vm.form.responseErrors.push(err)
              }
            }
          }
          vm.form.isLoading = false
        }
      } catch (e: any) {
        vm.form.isLoading = false
      }
    }
  },
  created (): void {
    const vm = this as any
    const params = vm.$route.params
    if (!params.token) vm.$router.replace('/')
  }
})
